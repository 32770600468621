@use '../utils/variables' as *;

body {
    position: relative;
}

figure {
    margin: 0;
}

.mw-100-img img {
    max-width: 100%;
}

.introduction-space > aside {
    margin: 1rem 0;

    @media (min-width: 768px) {
        margin: 1.9rem 0;
    }
}

.button-wtih-icon {
    align-items: center;
    display: inline-flex;
    justify-content: center;
    padding: 0.9rem 2rem;

    @media (min-width: 768px) {
        padding: 0.7rem 2rem;
    }

    > * {
        font-family: 'Inter-Bold', sans-serif;
        font-size: 1rem;
        margin: 0;

        @media (min-width: 768px) {
            font-size: $fs-h3;
        }
    }

    img {
        padding-left: 1rem;
    }
}

.button-more-with {
    width: 100%;

    @media (min-width: 768px) {
        width: 385px;
    }
}

.other-properties {
    .list {
        * {
            font-size: 18px;

            @media (min-width: 768px) {
                font-size: $fs-h4;
            }
        }
    }
}

.gradinet-1 {
    background:
        linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 12%, rgba(255, 0, 0, 0) 12%),
        linear-gradient(100deg, rgba(29, 44, 66, 1) 12%, rgba(0, 56, 147, 1) 100%);
}

.gradinet-2 {
    background: linear-gradient(90deg, rgba(29, 44, 66, 1) 12%, rgba(0, 56, 147, 1) 100%);
}

.p-about-box,
.p-kontakt-box {
    padding: 30px;

    @media (min-width: 768px) {
        padding: 40px;
    }
}

.shadow-1 {
    box-shadow: 0 21px 40px rgba(29, 44, 66, 0.15);
}

.row > * {
    @media (min-width: 1900px) {
        padding-left: 1.07rem;
        padding-right: 1.07rem;
    }
}

.mb-n-0 * {
    margin-bottom: 0;
}

.lang {
    text-transform: uppercase;

    &.active {
        display: none;
    }
}

strong,
.strong {
    font-family: 'Inter-Bold', sans-serif;
    font-weight: unset;
}

.column-over {
    @media (min-width: 992px) {
        align-items: center;
        flex-wrap: nowrap;

        > div {
            z-index: 2;
        }

        > aside {
            margin-left: -16%;
        }
    }
}

.footer {
    a {
        text-decoration: none;

        &:hover,
        &:focus {
            color: unset;
            text-decoration: underline;
        }
    }
}

.bg-gray {
    background-color: $gray;
}

.pt-1px {
    padding-top: 1px;
}
