@use '../utils/mixin';
@use '../utils/variables' as *;

@for $index from 1 through 6 {
    h#{$index} {
        color: $dark;
        font-family: 'Inter-Bold', sans-serif;
        font-weight: unset;
        margin-bottom: 1rem;
    }
}

// Title h 1-6 and display sizefs-
$min-width: 320px;
$max-width: 768px;

body * {
    line-height: 1.5;
}

h1,
.h1 * {
    line-height: 1;
    @include mixin.fluid-type($min-width, $max-width, 50px, $fs-h1);
}

h1 {
    margin-bottom: 1.875rem;

    @media (min-width: 991px) {
        margin-bottom: 2.375rem;
    }
}

h2,
.h2 * {
    @include mixin.fluid-type($min-width, $max-width, 25px, $fs-h2);
}

h3,
.h3 * {
    line-height: 1.3;
    @include mixin.fluid-type($min-width, $max-width, 22px, $fs-h3);
}

h3 {
    margin-bottom: 1.125rem;
}

h4,
.h4 * {
    @include mixin.fluid-type($min-width, $max-width, 18px, $fs-h4);
}

h5,
.h5 * {
    @include mixin.fluid-type($min-width, $max-width, 16px, $fs-h5);
}

h6,
.h6 * {
    margin-bottom: 0.5625rem;
    @media (min-width: 991px) {
        margin-bottom: 0.3125rem;
    }
    @include mixin.fluid-type($min-width, $max-width, 12px, $fs-h6);
}

h6 {
    margin-bottom: 0.5625rem;

    @media (min-width: 991px) {
        margin-bottom: 0.3125rem;
    }
}

.small-text,
.small-text * {
    font-size: 12px;
}

p {
    @include mixin.fluid-type($min-width, $max-width, 16px, $fs-h5);
}

.text-white * {
    color: rgba(var(--bs-white-rgb), var(--bs-text-opacity));
}

.text-map * {
    font-family: 'Inter-Bold', sans-serif;
    font-size: 40px;
    @include mixin.fluid-type($min-width, $max-width, 40px, 60px);
}
