@use '../utils/variables' as *;

.slick-center {
    margin: auto;
    max-width: calc(100vw - 16px);

    @media (min-width: 1755px) {
        max-width: 1755px;
    }

    .item {
        padding: 0 8px 40px;

        @media (min-width: 576px) {
            padding: 0 8px 40px;
        }

        > article {
            border-radius: 8px;
            padding: 40px 30px 24px;

            @media (min-width: 768px) {
                padding: 40px;
            }
        }
    }

    .slick-slide {
        filter: blur(5px);
        opacity: 0.8;
        transition: all 0.18s ease-in-out;
        width: 100vw;

        @media (min-width: 576px) {
            width: 540px;
        }

        @media (min-width: 768px) {
            width: 585px;
        }

        > article {
            border: 1px solid $white;
            box-shadow: none;
            transition: all 0.18s ease-in-out;
        }
    }

    .slick-center {
        filter: blur(0);
        opacity: 1;

        > article {
            border: 1px solid $secondary;
            box-shadow: 0 10px 10px rgba(29, 44, 66, 0.15);
        }
    }

    .owl-dots {
        span {
            background-color: $secondary;
            border-radius: 50%;
            display: inline-block;
            height: 10px;
            width: 10px;
        }

        .active span {
            background-color: $gray;
        }
    }
}

.slick-dots {
    li button {
        background-color: $secondary;
        border-radius: 50%;
        height: 10px;
        width: 10px;

        &::before {
            display: none;
        }
    }

    li.slick-active button {
        background-color: $dark;
    }
}

.slick-prev,
.slick-next {
    bottom: -26px;
    top: unset;
    z-index: 2;
}

.slick-prev {
    left: calc(50% - 90px);

    &::before {
        content: url('./../img/arrow-left.svg');
    }
}

.slick-next {
    left: calc(50% + 54px);

    &::before {
        content: url('./../img/arrow-right.svg');
    }
}

.slick-list {
    @media (min-width: 768px) {
        padding: 40px;
    }
}
