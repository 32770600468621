@use '../utils/variables' as *;

.btn,
button[type="submit"] {
    border-radius: 8px;
    border-width: 2px;
    font-family: 'Inter-Bold', sans-serif;
    font-size: $fs-h5;
    font-weight: unset;
    line-height: 130%;
    padding: 1.1rem 2rem;

    @media (min-width: 768px) {
        font-size: $fs-h3;
        padding: 0.85rem 2rem;
    }
}

.btn-lg {
    padding-bottom: 1rem;
    padding-top: 1rem;
}

.btn-sm {
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
}

.btn-primary {
    --bs-btn-active-bg: #1d2c42;
    --bs-btn-active-border-color: #1d2c42;
    --bs-btn-active-color: var(--bs-white);
    --bs-btn-bg: #003893;
    --bs-btn-border-color: #003893;
    --bs-btn-color: var(--bs-white);
    --bs-btn-hover-bg: #667995;
    --bs-btn-hover-border-color: #667995;
    --bs-btn-hover-color: var(--bs-white);
}

.btn-secondary {
    --bs-btn-active-bg: #667995;
    --bs-btn-active-border-color: #003893;
    --bs-btn-active-color: var(--bs-white);
    --bs-btn-bg: var(--bs-white);
    --bs-btn-border-color: #003893;
    --bs-btn-color: #003893;
    --bs-btn-hover-bg: #cfdbe9;
    --bs-btn-hover-border-color: #003893;
    --bs-btn-hover-color: #003893;
}

.btn-info {
    --bs-btn-active-bg: #667995;
    --bs-btn-active-border-color: #667995;
    --bs-btn-active-color: var(--bs-white);
    --bs-btn-bg: var(--bs-white);
    --bs-btn-border-color: var(--bs-white);
    --bs-btn-color: #003893;
    --bs-btn-hover-bg: #cfdbe9;
    --bs-btn-hover-border-color: #cfdbe9;
    --bs-btn-hover-color: #003893;
}
