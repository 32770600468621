@use '../utils/variables' as *;
@use './buttons';

/* bootstap settings start*/
:root {
    --bs-primary: $primary;
    --bs-info: $info;
    --bs-secondary: $secondary;
    --bs-dark: $dark;
    --bs-gray: $gray;

    --bs-primary-rgb: 0, 56, 147;
    --bs-secondary-rgb: 207, 219, 233;
    --bs-info-rgb: 249, 250, 251;
    --bs-dark-rgb: 29, 44, 66;
    --bs-gray-rgb: 102, 121, 149;

    --bs-body-font-family: 'Inter-Regular';
    --bs-body-font-size: 1rem;
    --bs-body-line-height: 1.5;

    --bs-link-color: #003893;
    --bs-link-hover-color: #003893;
    --bs-body-color: #1d2c42;
    --bs-body-bg: #f9fafb;
}

/* bootstap settings end*/

/* custom colors start*/
$user-colors: (
    "primary": --bs-primary-rgb,
    "secondary": --bs-secondary-rgb,
    "dark": --bs-dark-rgb,
    "info": --bs-info-rgb,
    "gray": --bs-gray-rgb,

);

@mixin create-colors($name, $color) {
    .text-#{$name},
    .text-#{$name} * {
        color: rgba(var($color), 1);
    }
}

@each $name,
    $color in $user-colors {
    @include create-colors($name, $color);
}

/* custom colors end */
