.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
    width: 100%;
}

@media(min-width: 576px) {
    .container-sm,
    .container {
        max-width: 100%;
    }
}

@media(min-width: 768px) {
    .container-md,
    .container-sm,
    .container {
        max-width: 100%;
    }
}

@media(min-width: 992px) {
    .container-lg,
    .container-md,
    .container-sm,
    .container {
        max-width: 100%;
    }
}

@media(min-width: 1200px) {
    .container-xl,
    .container-lg,
    .container-md,
    .container-sm,
    .container {
        max-width: 100%;
    }
}

@media(min-width: 1400px) {
    .container-xxl,
    .container-xl,
    .container-lg,
    .container-md,
    .container-sm,
    .container {
        max-width: 100%;
    }
}

@media (min-width: 1450px) {
    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl {
        max-width: 1400px;
    }
}
