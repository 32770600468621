// header-container
.header-container {
    padding-top: 14px;
    transition: all 0.15s ease-in-out;
    width: 100%;

    > .row {
        margin: 0;
        padding: 0;

        @media (max-width: 991px) {
            height: 0;
        }

        > .nav-control {
            @media (max-width: 991px) {
                height: 0;
            }
        }
    }

    .col {
        padding: 0;
    }

    .header-mobile {
        width: 100%;
    }

    .logo {
        margin-left: 15px;
    }

    #toggleMenu {
        cursor: pointer;
        height: 50px;
        width: 50px;
    }

    @media (max-width: 991px) {
        height: 80px;
    }

    @media (min-width: 576px) {
        max-width: 100%;

        .header-mobile {
            margin: auto;
        }
    }

    @media (min-width: 992px) {
        margin-left: auto;
        margin-right: auto;
        padding: 0;

        .header-mobile {
            margin-left: 0;
            max-width: 200px;
        }
    }

    @media (min-width: 1200px) {
        max-width: 100%;
        max-width: 1320px;
    }

    @media (min-width: 1450px) {
        max-width: 1400px;
    }
}
