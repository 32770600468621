/* font start */
@font-face {
    font-family: 'Inter-Regular';
    src: url('../fonts/Inter-Regular.ttf');
}

@font-face {
    font-family: 'Inter-Bold';
    src: url('../fonts/Inter-Bold.ttf');
}

/* font end */

/* Base colors start */
$primary: #003893; // 0, 56, 147
$secondary: #cfdbe9; // 207, 219, 233, 1 // border
$dark: #1d2c42; // 29, 44, 66
$gray: #667995; // 102, 121, 149
$info: #f9fafb; //249, 250, 251
$white: #fff;

$fs-h1: 74px; // 4.625
$fs-h2: 32px; // 2
$fs-h3: 22px; // 1.375
$fs-h4: 20px; // 1.25
$fs-h5: 16px; // 1
$fs-h6: 14px; // 0.875

@function space($space) {
    @return map-get($spacers, $space);
}

$border-radius: 7px;
