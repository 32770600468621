@use './../utils/variables' as *;

// menu mobile
.main-header nav {
    width: 100%;

    @media (max-width: 991px) {
        background-color: $info;
        opacity: 0;
        transform: translateX(105%);
        transform-origin: top;
        transition: all 0.25s ease-in-out;
        z-index: 102;
    }

    @media (min-width: 992px) {
        display: block;
        margin-top: 0;
        width: auto;
    }
}

.show-body .main-header nav {
    @media (max-width: 991px) {
        opacity: 1;
        transform: translateX(0);
        transform-origin: top;
    }
}

#toggleMenu {
    display: block;
    position: relative;
    width: 25px;

    @media (min-width: 992px) {
        display: none;
    }
}

.menu-icon {
    bottom: 0;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
}

#closeMenu {
    transform: scale(0);
    transition: all 0.15s ease-in-out;
}

#openMenuWhite {
    transform: scale(0);
}

#openMenuBlack {
    transform: scale(1);
    transition: all 0.15s ease-in-out;
}

.hide-content {
    background-color: #000;
    height: 0;
    opacity: 0;
    position: absolute;
    transition: all 0.25s ease-in-out;
    transition-delay: 0.15s;
    width: 100%;
    z-index: -1;
}

.show-body {
    #openMenuBlack {
        transform: scale(0);
    }

    #closeMenu {
        transform: scale(1);
    }

    .scroll-up,
    .scroll-down {
        background-color: $white;

        nav {
            background-color: $white;
        }
    }

    .hide-content {
        height: 100%;
        opacity: 0.5;
        z-index: 101;
    }
}
