@use '../utils/variables' as *;

.collapse-box {
    padding-bottom: 70px;

    @media (min-width: 768px) {
        padding-bottom: 120px;
    }

    header {
        cursor: pointer;

        > * {
            margin: 0;
            padding: 1rem 79px 1rem 30px;
            position: relative;

            @media (min-width: 768px) {
                padding: 1rem 30px;
            }

            * {
                margin: 0;
            }

            &::after {
                background: url('../img/arrow.svg') center center no-repeat;
                content: '';
                display: block;
                height: 40px;
                position: absolute;
                right: 20px;
                top: 10px;
                transform: rotate(180deg);
                transition: all 0.2s ease-in-out;
                width: 40px;
            }
        }
    }
}

[data-bs-toggle='collapse'][aria-expanded='true'] {
    > * {
        &::after {
            transform: rotate(0);
            transition: all 0.2s ease-in-out;
        }
    }
}

.collapse-box {
    header {
        background-color: $white;
        border-radius: 8px;
        margin-top: 1rem;

        &[aria-expanded='false'] * {
            border: 1px solid $secondary;
            border-radius: 8px;
        }

        &[aria-expanded='true'] * {
            border: 1px solid $secondary;
            border-bottom: 0;
            border-radius: 8px;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
    }

    aside {
        background-color: $white;
        border: 1px solid $secondary;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        border-top: 0;
        opacity: 0;
        transition: all 0.15s;

        > * {
            padding: 0 30px 14px;
        }
    }

    aside.collapsing,
    aside.show {
        opacity: 1;
    }
}

[data-toggle='collapse'] {
    .box-arrow {
        height: 6px;
        position: relative;
        width: 38px;

        &::after {
            background: url('../img/arrow_white.svg') center center no-repeat;
            content: '';
            display: block;
            height: 10px;
            position: absolute;
            right: 0;
            top: 0;
            transform: rotate(180deg);
            transition: all 0.2s ease-in-out;
            width: 20px;
        }
    }
}

[data-toggle='collapse'][aria-expanded='true'] {
    .box-arrow {
        &::after {
            transform: rotate(0deg);
        }
    }
}
